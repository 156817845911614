<template>
    <div v-loading="$wait.is('loading')" class="card w-full self-start manager-general" element-loading-spinner="el-custom-spinner">
        <ModalChangePassword
            v-if="modalChangePasswordVisible"
            :modalChangePassword="modalChangePassword"
            @close="modalChangePassword = false"
            @closed="modalChangePasswordVisible = false"
        />

        <header class="border-b border-solid border-gray-300 pb-4 mb-5">
            <h1>{{ $t('managers.general_information') }}</h1>
        </header>

        <section>
            <el-form :model="managerData" :rules="validationRules" label-width="200px">
                <el-row :gutter="15" class="border-b border-solid border-gray-300 pb-4 mb-5">
                    <el-col :span="13" :offset="3">
                        <el-form-item :label="$t('employees.name')">
                            <el-input v-model="managerData.name" class="pl-3" />
                        </el-form-item>
                        <el-form-item :label="$t('employees.surname')">
                            <el-input v-model="managerData.surname" class="pl-3" />
                        </el-form-item>
                        <el-form-item label="Personummer">
                            <el-input v-model="managerData.personnummer" class="pl-3" />
                        </el-form-item>
                        <el-form-item label="Email">
                            <el-input v-model="managerData.email" class="pl-3" />
                        </el-form-item>
                        <el-form-item :label="$t('employees.telephone')">
                            <el-input v-model="managerData.telephone" class="pl-3" />
                        </el-form-item>
                        <el-form-item :label="$t('common.permission_role')">
                            <el-select v-model="selectedRoleId" class="pl-3">
                                <el-option
                                    v-for="item in roles"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </section>

        <div class="flex justify-end">
            <el-button type="primary" :disabled="!userCan('update managers')" @click="update">
                {{ $t('common.update') }}
            </el-button>

            <el-button type="warning" :disabled="!userCan('update managers')" @click="changePasswordModal">
                {{ $t('employees.change_password') }}
            </el-button>
        </div>
    </div>
</template>
<script>
export default {
    components: {
        ModalChangePassword: () => import(/* webpackChunkName: "ManagerGeneral/ModalChangePassword" */ './components/ModalChangePassword'),
    },

    data() {
        return {
            modalChangePasswordVisible: false,
            modalChangePassword:        false,
            managerData:                {},
            roles:                      [],
            selectedRoleId:             null,
            validationRules:            {},
        };
    },

    beforeCreate() {
        this.$emit('activeSubpage', 'general');
    },

    created() {
        this.getDetails();
        this.getRoles();
    },

    methods: {
        async changePasswordModal() {
            this.modalChangePasswordVisible = true;
            await new Promise(resolve => setTimeout(resolve, 50));
            this.modalChangePassword = true;
        },

        async getDetails() {
            this.$wait.start('loading');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/managers/${this.$route.params.uuid}`);
            this.managerData = data;
            this.selectedRoleId = data.role_id;
            this.$wait.end('loading');
        },

        async getRoles() {
            this.$wait.start('loading');
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/roles_and_permissions/roles`);
            this.roles = data;
            this.$wait.end('loading');
        },

        async update() {
            this.$wait.start('loading');
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/managers/${this.$route.params.uuid}`, {
                role_id: this.selectedRoleId,
                name: this.managerData.name,
                surname: this.managerData.surname,
                personnummer: this.managerData.personnummer,
                email: this.managerData.email,
                telephone: this.managerData.telephone,
            });
            this.$notify.success({ title: 'Success' });
            this.$wait.end('loading');
        },
    },
};
</script>
